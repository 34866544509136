.screen {
  margin-left: 250px;
  position: relative;
  top: 80px;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  overflow-y: auto;
}

@media screen and (max-width: 959px) {
  .screen {
    margin-left: 0;
    top: 64px;
    height: calc(100vh - 64px);
  }
}

@media screen and (max-width: 599px) {
  .screen {
    top: 56px;
    height: calc(100vh - 56px);
  }
}

.screen--no-side-menu {
  margin-left: 0;
  height: auto;
}
