.nav-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-links li {
  padding: 0 0.2rem;
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}