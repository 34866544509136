@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.u-margin-bottom-lg {
  margin-bottom: 50px;
}

.u-margin-bottom-md {
  margin-bottom: 35px;
}

.u-margin-bottom-sm {
  margin-bottom: 20px;
}

.u-margin-top-lg {
  margin-top: 50px;
}

.u-margin-top-xl {
  margin-top: 65px;
}

.u-margin-top-md {
  margin-top: 35px;
}

.u-margin-top-25 {
  margin-top: 25px;
}

.u-margin-top-sm {
  margin-top: 20px;
}

.u-padding-top-lg {
  padding-top: 50px;
}

.container-100 {
  width: 100%;
  position: relative;
}

.u-margin-right-xs {
  margin-right: 10px;
}

.u-break-overflown-word {
  @include break-overflown-word;
}
