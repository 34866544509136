@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.home-dashboard {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.5rem;
  padding: 4rem 1.5rem;

  @include respond(bp1200) {
    padding: 2rem 1.5rem 3.5rem;
  }

  @include respond(bp600) {
    padding: 1rem 0.75rem 3.5rem;
    grid-gap: 1rem;
  }
}
