.file-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.file-upload__img-preview {
  margin-top: 1rem;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  margin-bottom: 1rem;
  object-fit: cover;
}

.file-up-img {
  height: 365px;
  width: auto;
  object-fit: fill;
}

.file-up-img--wide {
  width: 365px;
  height: auto;
  object-fit: fill;
}

.file-upload__input {
  display: none;
}

.file-upload__preview-container {
  width: 100%;
  display: flex;
}

.file-upload__preview {
  font-size: 1rem;
  padding: 1.05rem 2rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.07);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
  font-family: "Noto Sans", "Helvetica", "Arial", sans-serif;
}

.file-upload__preview-link,
.file-upload__preview-link:link,
.file-upload__preview-link:visited {
  display: block;
  font-size: 1.5rem;
  padding: 1.05rem 2rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #333;
  transition: all 0.2s;
  width: 100%;
  font-family: "Noto Sans", "Helvetica", "Arial", sans-serif;
}

.file-upload__preview-link:hover {
  color: #007bed;
}

.file-upload__btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  height: auto;
  cursor: pointer;
  border: none;
  background-color: #007bed;
  transition: all 0.2s;
}

.file-upload__btn:hover,
.file-upload__btn:active {
  background-color: #005baf;
}

.file-upload__btn svg {
  color: #fff;
}

@media screen and (max-width: 599px) {
  .file-upload__preview {
    width: 220px;
    max-width: 220px;
  }

  .file-img {
    height: 280px;
    width: auto;
    object-fit: fill;
  }

  .file-up-img--wide {
    width: 280px;
    height: auto;
    object-fit: fill;
  }
}
