@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.sale-prop-item {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  padding: 15px 20px;
  width: 100%;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  cursor: pointer;
  color: $color-primary-dark;
  transition: all 0.2s ease-in-out;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) max-content;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;

  & svg {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover,
  &:active {
    // background-color: rgba($color-primary-dark, 0.9);
    // color: #fff;

    // & svg {
    //   color: #fff;
    // }
  }

  &--highlighted {
    color: #fff;
    background-color: #0097a7;

    & svg {
      color: #fff !important;
    }
  }

  &__data-item {
    display: block;
    margin: 0;
    max-width: 100%;
    font-size: 0.85rem;
    font-weight: 700;
    @include ellipsis;

    &--thin {
      font-weight: 400;
      margin-bottom: 0.3rem;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 7px;
    align-items: center;
  }

  &__details {
    grid-column: 1 / -1;
  }

  &__details-content {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #bbb;
  }
}
