.whatsapp-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}

.whatsapp-btn-window-form {
  box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
  border-radius: 4px;
  width: 400px;
  position: fixed;
  right: 20px;
  bottom: 88px;
  z-index: 1000;
  border: solid 1px #bbb;
  background-color: #fff;
}

.whatsapp-btn-window {
  box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
  border-radius: 4px;
  width: 260px;
  position: fixed;
  right: 20px;
  bottom: 88px;
  z-index: 1000;
  border: solid 1px #bbb;
}

.whatsapp-btn-window-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #007bed;
  padding: 0.3rem 0.3rem 0.3rem 0.8rem;
  display: flex;
}

.whatsapp-btn-window-message-body {
  background-color: #a3d6ff;
  padding: 0.8rem;
}

.whatsapp-btn-window-message-form-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
  padding: 0.8rem;
}

.whatsapp-btn-window-message-form {
  display: grid;
  grid-template-columns: 167px 48px;
  grid-gap: 7px;
}

@media screen and (max-width: 601px) {
  .whatsapp-btn-window-form {
    width: 320px;
  }
}
