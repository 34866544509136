@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.home-carousel {
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  &__container {
    box-sizing: border-box;
    position: relative;
    grid-column: 4 / 13;
    padding: 1rem;
    background-color: #fff;
    box-shadow: $default-box-shadow;
    border-radius: $default-border-radius;
    order: 2;

    @include respond(bp1200) {
      order: 1;
      margin-bottom: 2rem;
    }

    @include respond(bp1200) {
      grid-column: 1 / -1;
      // margin: 0;
      // padding: 0;
      // background-color: transparent;
      // box-shadow: none;
      // border-radius: none;
    }

    @include respond(bp600) {
      padding: 0.5rem;
      margin-bottom: 2.75rem;
    }
  }

  &__slide {
    position: relative;
    width: 100%;
    aspect-ratio: 2.4 / 1;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    // @include respond(bp600) {
    //   aspect-ratio: 1.42 / 1;
    // }
  }

  &__slide-img {
    visibility: hidden;
  }
}
