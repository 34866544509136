@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.sale-dash {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba($color-black, 0.75);
  z-index: 501;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  @include center-xy-axis-vertical;

  &--active {
    z-index: 501;
    opacity: 1;
    visibility: visible;
  }

  &__content {
    position: relative;
    width: 90vw;
    height: 90vh;
    display: grid;
    grid-template-columns: 400px 1fr;
    align-content: start;
    box-sizing: border-box;
    background-color: $color-white;
    border-radius: $default-border-radius;
    overflow: hidden;
    grid-gap: 0;
    box-shadow: 0px 0px 5px -1px rgb(0 0 0 / 25%);

    @include respond(bp1300) {
      width: 95vw;
      grid-template-columns: 300px 1fr;
    }

    @include respond(bp900) {
      width: 95vw;
      grid-template-columns: 1fr;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &--active > &__content {
    opacity: 1;
  }

  & * {
    box-sizing: border-box;
  }

  &__controllers {
    grid-column: 1 / -1;
    background-color: #ccc;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 10px;
  }

  &__title-container {
    grid-column: 1 / -1;
    padding: 10px 20px 20px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: $font-title;
    font-size: 1.2rem;
    margin: 0;
    color: $color-primary-dark;
  }

  &__col-left,
  &__col-right {
    overflow-y: auto;
    height: 100%;

    @include respond(bp900) {
      overflow-y: visible;
    }
  }

  &__col-right {
    background-color: #eee;
  }

  &__col-right-content {
    position: relative;
    min-height: calc(90vh - 38px);

    @include respond(bp900) {
      min-height: 60vh;
    }
  }

  &__cust-data {
    width: 100%;
    padding: 15px 0 20px;
  }

  &__cust-data-item {
    padding: 0 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.5rem 1rem;
    align-items: center;
  }

  &__cust-data-item-label {
    display: inline-block;
    margin: 0;
    font-weight: 700;
    font-size: 0.8rem;
  }

  &__cust-data-item-content {
    display: inline-block;
    margin: 0;
    max-width: 100%;
    font-size: 0.95rem;
    @include break-overflown-word;

    &--blue {
      color: $color-primary;
    }

    &--red {
      color: $color-secondary;
    }
  }

  &__cust-data-actions {
    margin-left: auto;
    display: grid;
    margin-left: 0.25rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.1rem;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__cust-data-item-subcontent {
    display: inline-block;
    width: 100%;

    &--clickable {
      color: $color-primary;
      cursor: pointer;
    }
  }

  &__col-right-tabs {
    background-color: #fff;
    box-shadow: 0px 0px 7px -1px rgb(0 0 0 / 25%);
    padding-top: 3.5rem;

    @include respond(bp900) {
      padding-top: 0.5rem;
      box-shadow: none;
    }
  }

  &__col-right-content-empty {
    color: $color-primary-dark;
    padding: 20px 50px 0;
  }

  &__col-right-content-spinner {
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__col-right-content-load-more {
    margin-bottom: 30px;
    width: 100%;
    @include center-xy-axis-horizontal;
  }

  &__col-right-add-item-btn {
    width: 100%;
    margin-bottom: 20px;
    @include center-xy-axis-horizontal;
  }

  &__proposals {
    position: relative;
    margin-bottom: auto;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: start;
    position: relative;

    @include respond(bp1500) {
      grid-template-columns: 1fr 1fr;
    }

    @include respond(bp1000) {
      grid-template-columns: 1fr;
    }
  }

  &__history {
    position: relative;
    margin-bottom: auto;
    padding: 30px 15%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: start;

    @include respond(bp1500) {
      padding: 30px 10%;
    }

    @include respond(bp1000) {
      padding: 30px;
    }
  }

  &__add-btn-fixed {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  &__filters-container {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
  }

  &__filters-btn {
    display: flex;
    justify-content: start;
  }

  &__filters-popover {
    width: 600px;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @include respond(bp1200) {
      width: 400px;
    }

    @include respond(bp600) {
      width: 250px;
    }
  }
}
